<template>
    <div>
        <MXPageLayout>
            <div slot="filter">
                <Row>
                    <Form style="display:flex;justify-content:end" ref="formInline" :model="fromData" inline :label-width="10" label-position="right">
                        <FormItem>
                            <Select v-model="fromData.villageId" placeholder="全部小区" clearable style="width:180px">
                                <Option v-for="(item, index) in villageFormItemSelectList" :key="index" :value="item.v_village_id">
                                    {{ item.v_village_name }}</Option>
                            </Select>
                        </FormItem>
                        <FormItem>
                            <DatePicker @on-change="fromData.selectStartTime = $event" :value="fromData.selectStartTime" format="yyyy-MM-dd" type="date" placeholder="开始时间">
                            </DatePicker>
                        </FormItem>
                        <Button type="primary" shape="circle" icon="ios-search" @click="searchList()">搜索
                        </Button>
                    </Form>
                </Row>
            </div>
            <div slot="title">
                {{pageTitle}}
            </div>
            <div slot="content">
                <div class="echart-box" id="E_zkcl" style="width:100%;height:600px;"></div>
            </div>
        </MXPageLayout>
    </div>
</template>
<script>
import { Message } from 'view-design'
import { villagePedestrianFlowStatistics } from '@/api/ststistics/index.js'
import { searchElevatorNotPage } from '@/api/elevator/index.js'
import * as echarts from 'echarts'
import chinaCities from 'echarts/map/json/china-cities.json'
import chongqing from "@/assets/chongqing.json";
import "echarts-gl";
import {
    searchVillageSelectList
} from '@/api/home/index.js'
import Statistics from '@/pages/Statistics/statistics'

export default {
    data() {
        return {
            DisplayTypeDataList: [{
                    value: 'asc',
                    label: '升序'
                },
                {
                    value: 'desc',
                    label: '降序'
                }
            ],
            model1: '',
            fromData: {
                villageId: '',
                selectStartTime: this.formatDateToStartOfDay(this.formatDate(-1)),
                selectEndTime: this.formatDateToEndOfDay(this.formatDate(-1)),
            },
            elevatorSelectList: [],
            zoom: 1,
            villageFormItemSelectList: [],

        }
    },
    computed: {
        theme() {
            return this.$store.getters.theme
        },
        user() {
            return this.$store.state.user
        },
    },
    mounted() {
        this.reqVillageList()
    },
    mixins: [Statistics],
    watch: {
        'theme'(newVal, oldVal) {
            if (newVal != oldVal) {
                this.searchList()
            }
        }
    },
    methods: {
        searchList() {
			if (!this.fromData.villageId) {
			  this.$message.error('请选择小区')
			  return
			}
            let params = JSON.parse(JSON.stringify(this.fromData))
            // params.selectEndTime = this.formatDateToEndOfDay(params.selectStartTime)
            params.selectStartTime = this.formatDateToStartOfDay(params.selectStartTime)
            let startTime = params.selectStartTime + ""
            params.selectEndTime = this.formatDateToEndOfDay(startTime.slice(0, 10))
            villagePedestrianFlowStatistics(params).then(res => {
                let data = res.info
                let myChart = this.$echarts.init(document.getElementById('E_zkcl'), 'dark')
				this.chart = myChart
                let legendData = data.series.restList.map((item) => {
                    if (item.type == 'up') return '进入流量'
                    else
                        return '出行流量'
                })
                let seriesData = data.series.restList.map((item) => {
                    return {
                        name: item.type == 'up' ? '进入流量' : '出行流量',
                        type: 'line',
                        // stack: 'Total',
                        data: item.data,
                        itemStyle: {
                            normal: {
                                color: item.type == 'up' ? '#5a71c0' : '#9eca7f', //改变折线点的颜色
                                lineStyle: {
                                    color: item.type == 'up' ? '#5a71c0' : '#9eca7f' //改变折线颜色
                                },
                                label: { show: true, color: '#fff' }
                            }
                        }
                    }
                })
                let option = {
                    backgroundColor: '',
                    title: {
                        text: ''
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: legendData,
                        // itemStyle: {
                        //     color: '#fff'
                        // },
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    // toolbox: {
                    //     feature: {
                    //         saveAsImage: {}
                    //     }
                    // },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: data.xaxis.data.map(d => d.split(" ")[1].slice(0, 5)),
                        axisLabel: {
                            formatter: '{value}',
                            textStyle: {
                                fontSize: 12,
                                color: '#fff'
                            },
                            rotate: 36,

                        },

                    },
                    yAxis: {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}',
                            textStyle: {
                                fontSize: 16,
                                color: '#fff'
                            }
                        }
                    },
                    series: seriesData
                };

                myChart.setOption(option)

            })
        },
        reqVillageList() {
            searchVillageSelectList(this.buildParams({})).then(res => {
                this.villageFormItemSelectList = res.info
                if (this.villageFormItemSelectList.length)
                    this.fromData.villageId = this.villageFormItemSelectList[0].v_village_id
                this.searchList()
            })
        },
        getModuleName() {
            return '小区人流量'
        },
        buildParams(params) {
            // params.vProjectId = this.user.user.v_project_id
            return params
        },
        onChangeEndTime(e) {
            // this.fromData.endTime = e + ' 23:59:59'
        },
    }
}
</script>
<style scoped>
.home {
    padding: 10px 30px 0 10px;
}

::v-deep .ivu-select-selected-value {
    color: #fff !important;
}
</style>